/* Основные стили футера */
.container{
  max-width: 1600px;

}
.footer-info {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  padding: 20px 0;
}

.footer {
  background-color: #b50027;
  width: 100%;
  margin-top: 30px;
  padding: 20px 0;
}

.logo pre {
  color: rgb(87, 122, 153);
}

.foot-right {
  display: flex;
  gap: 30px;
  flex-wrap: nowrap;
  margin-top: 30px;
  max-width: 100%;
}

.foot-right div {
  flex-shrink: 0;
  flex-basis: auto;
  padding: 10px;
}

.foot-right a {
  color: white;
  text-decoration: none;
  background-color: #b50027;
  display: inline-block;
  text-align: center;
}

.foot-right ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.btn button {
  margin-top: 20px;
  background-color: rgb(87, 122, 153);
  padding: 10px 15px;
  outline: none;
  border-radius: 12px;
  border: none;
  color: white;
}

.caaaaa a {
  color: white !important;
}

.contactuss {
  color: white;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  /* width: 100%; */
  padding: 0 5%;
}

.foot-logo a {
  color: white !important;
  font-size: 68px;
  text-decoration: none;
  font-family: "Archivo Black", sans-serif !important;
  font-weight: 400;
}

.foot-logo span {
  font-family: "Times New Roman", Times, serif;
  font-size: 110px;
}

.xidmet ul {
  list-style-type: none;
  color: white;
}

.xidmet li {
  text-align: start !important;
}

/* Медиазапросы для различных размеров экранов */

/* Экраны до 1200px */
@media (max-width: 1020px) {
  .footer-info {
    flex-direction: column;
    align-items: center;
  }

  .foot-right {
    flex-direction: column;
    gap: 20px;
    text-align: center;
  }

  .foot-right div {
    padding: 5px;
  }

  .contactuss {
    flex-direction: column;
    align-items: center;
    padding: 0 15px;
  }

  .contactuss p,
  .social-con {
    text-align: center;
    margin-bottom: 15px;
  }
  .social-con{
    display: flex;
    justify-content: center;
  }

  .foot-logo a {
    font-size: 48px;
  }

  .foot-logo span {
    font-size: 80px;
  }
  .xidmet li{
    text-align: center !important;
  }
} 

/* Экраны до 768px */
@media (max-width: 768px) {
  .footer {
    padding: 20px 10px;
  }

  .foot-right {
    gap: 10px;
  }

  .contactuss {
    padding: 0 10px;
  }

  .foot-logo a {
    font-size: 36px;
  }

  .foot-logo span {
    font-size: 60px;
  }

  .social-con a {
    margin: 0 5px;
  }
}

/* Экраны до 480px */
@media (max-width: 480px) {
  .footer-info {
    text-align: center;
  }

  .foot-right {
    flex-direction: column;
    gap: 10px;
    padding: 10px 0;
  }

  .foot-right ul {
    padding-left: 0;
  }

  .contactuss {
    flex-direction: column;
    align-items: center;
  }

  .contactuss p {
    font-size: 14px;
  }

  .social-con a {
    margin: 0 3px;
  }
}

/* Экраны до 280px */
@media (max-width: 280px) {
  .footer {
    padding: 10px 0;
  }

  .foot-logo a {
    font-size: 28px;
  }

  .foot-logo span {
    font-size: 40px;
  }

  .footer-info {
    flex-direction: column;
    text-align: center;
    padding: 10px 0;
  }

  .foot-right {
    gap: 5px;
  }

  .foot-right div {
    padding: 3px;
  }

  .foot-right ul {
    padding-left: 0;
  }

  .foot-right li {
    font-size: 12px;
  }

  .contactuss {
    flex-direction: column;
    align-items: center;
    padding: 0 5px;
  }

  .contactuss p {
    font-size: 12px;
    text-align: center;
  }

  .social-con {
    margin-top: 10px;
  }

  .social-con a {
    margin: 0 2px;
  }
}
@media (min-width: 1600px) {
  .container {
    width: 1600px; /* Fixed width for screens wider than 1600px */

  }
  .footer-info {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    padding: 20px 0;
  }
}
