/* Additional Styles for Header Component */
@import url('https://fonts.googleapis.com/css2?family=Archivo+Black&display=swap');

.hamburger{
  display: none;
  margin-top: -10px;
}
.barar{
  margin: 5px 0;
}
.hamburger .bar {
  height: 5px; /* Increase height for better visibility */
  width: 25px;
  background-color: black;
  transition: 0.3s;
}

/* Navbar Adjustments */
.navbar {
  background-color: #ebebeb;
  padding: 10px;
  display: flex;
  gap: 50px;
  padding-right: 4%;
  justify-content: flex-end;
  margin-top: 10px;
}

.navbar.open {
  display: flex; 
  flex-direction: column;
}

/* General Styles */
.mini-lang-bar {
  display: flex;
  justify-content: space-between;
  padding: 0 50px;
  align-items: center;
}

.line {
  width: 100%;
  height: 5px;
  background-color: rgb(51, 84, 112);
}

.left {
  display: flex;
}

.right {
  display: flex;
  gap: 30px;
}

.right a {
  color: black;
}

.logo {
  background-color: #fff;
  margin-top: 10px;
  width: 400px;
  height: 146px;
  display: flex;
  justify-content: center;
  align-items: center;
  
}

#logo {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  gap: 20px;
}

.logo a {
  color: #b50027;
  font-size: 55px;
  text-decoration: none;
  font-family: "Archivo Black", sans-serif !important;
  font-weight: 400;
  margin-top: -20px;
}

.logo span {
  font-family: 'Times New Roman', Times, serif !important;
  font-size: 110px;
}

.social-con {
  display: flex;
  gap: 20px;
  padding-top: 10px;
  align-items: center;
}

.social-con img {
  width: 20px;
}

.contact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
}

.contact a {
  color: rgb(51, 84, 112) !important;
  text-decoration: none;
}

.btn {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding-top: 10px;
  width: 200px;
  /* padding-right: 10px; */
}

.btn a {
  background-color: rgb(51, 84, 112);
  padding: 2px 4px;
  border: 1px solid rgb(51, 84, 112);
  color: white;
  border-radius: 15px;
  outline: none;
  text-decoration: none;
  display: flex;
  justify-content: center;
}

.navbar a {
  color: black;
  text-decoration: none;
  transition: .3s ease;
  cursor: pointer;
  padding-right: 2.5%;
  font-size: 24px;
}

.navbar a:hover {
  color: #b50027;
  transform: scale(110%);
}

.navbar select {
  width: 12%;
  background-color: #ebebeb;
  outline: none;
  border: none;
  color: black;
  transition: .3s ease;
  font-size: 24px;
}

.navbar select:hover {
  color: #b50027;
  transform: scale(110%);
}
.cano,.defe{
  display: none !important;
}
@media(max-width:1255px){
  .btn {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding-top: 10px;
    width: 150px;
    /* padding-right: 10px; */
  }
}
@media(max-width:1130px){
  .btn {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding-top: 10px;
    width: 100px;
    text-align: center;
    /* padding-right: 10px; */
  }
}
/* Responsive Styles */
@media (max-width: 1020px) {
  .navbar {
    display: none; /* Hide default navbar on mobile */
  }
  .hamburger {
    display: flex; /* Show hamburger on mobile */
    flex-direction: column;
  }

  .contact {
    flex-direction: column;
  }

  .navbar select {
    width: 220px;
  }

  .anoc{
    display: none;
  }


  #logo{
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
  }

  .logo{
    display: flex;
    justify-content: start;
    align-items: start;
    width: 100%;
  }
  .logo a {
    font-size: 48px;
    margin: 0;
  }

  .logo span {
    font-size: 80px;
  }
  .defe{
    display: flex !important;
    margin-top: -60px;
    justify-content: start !important;
    width: 200px !important;
    gap: 10px !important;
  }
  .btn-mobi{
    width: 200px !important;
  }

.mobi-tel{
  width: 100% !important;
  display: flex;
  justify-content: center;
}
  

}
@media(max-width:768px){
  .lo-ani{
    display: none !important;
  }
  .cano{
    display: flex !important;

  } 
  .animation{
    margin-top: -20px;
  }
  .container-anim{
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .animation{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100% !important;
  }

  .logo a {
    font-size: 36px;
    margin-top: 20px;
  }

  .logo span {
    font-size: 60px;
  }
}

/* @media(max-width:500px){
  .logo a {
    color: #b50027;
    font-size: 45px;
    text-decoration: none;
    font-family: "Archivo Black", sans-serif !important;
    font-weight: 400;
    margin-top: -20px;
  }
  
  .logo span {
    font-family: 'Times New Roman', Times, serif !important;
    font-size: 110px;
  }
} */

@media(max-width:280px){
  .logo a {
    font-size: 28px;
  }

  .logo span {
    font-size: 40px;
  }
}

@media (min-width: 1600px) {
  .container {
    width: 1600px; /* Fixed width for screens wider than 1600px */
  }
}


