@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap");

* {
  font-family: "Noto Sans", sans-serif;
}

/* p {
  text-shadow: 0 0 7px rgba(255,255,255,.3), 0 0 3px rgba(255,255,255,.3);
} */
.container-anim {
  /* color: #e5e5e5; */
  color: rgb(51, 84, 112);
  font-size: 28px;
  /* text-transform: uppercase; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.animation {
  height: 60px;
  padding-top: 10px;
  overflow: hidden;
  /* margin-left: 2rem;     */
}

.animation > div > div {
  padding: 0.25rem 0.75rem;
  height: 2.81rem;
  margin-bottom: 2.81rem;
  display: inline-block;
}

.animation div:first-child {
  animation: text-animation 8s infinite;
}

.first div {
  background-color: #ffffff;
}

.second div {
  background-color: #ffffff;
}

.third div {
  background-color: #ffffff;
}

@keyframes text-animation {
  0% {margin-top: 0;}
  10% {margin-top: 0;}
  20% {margin-top: -5.62rem;}
  30% {margin-top: -5.62rem;}
  40% {margin-top: -11.24rem;}
  60% {margin-top: -11.24rem;} 
  70% {margin-top: -5.62rem;}
  80% {margin-top: -5.62rem;}
  90% {margin-top: 0;}
  100% {margin-top: 0;}
}

@media(max-width:1350px){
.animation{
  /* width: 80%; */
  margin-left: 0px;

}
.container-anim {
  font-size: 22px; /* Smaller font size for medium screens */
}
}

/* Responsive Styles */
@media (max-width: 1200px) {
  .container-anim {
    font-size: 18px; /* Smaller font size for medium screens */
  }
  .animation {
    height: 50px; /* Adjust height for medium screens */
  }
}

@media (max-width: 1170px) {
  .container-anim {
    font-size: 18px; /* Smaller font size for medium screens */
  }
  .animation {
    height: 50px; /* Adjust height for medium screens */
  }
}

@media (max-width: 1075px) {
  .container-anim {
    font-size: 15px; /* Smaller font size for medium screens */
  }
  .animation {
    height: 50px; /* Adjust height for medium screens */
  }
}

@media(max-width:1000px){
  .container-anim{
    font-size: 16px;
  }
}

@media (max-width: 800px) {
  .container-anim {
    font-size: 16px; /* Smaller font size for small screens */
  }
  .animation {
    height: 50px; /* Further adjust height for small screens */
  }
}
@media(max-width:768px){
  .lo-ani{
    display: none;
  }
  .cano{
    display: flex !important;

  } 
  .animation{
    margin-top: 0px;
  }
  .container-anim{
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .animation{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100% !important;
  }
}

@media (max-width: 500px) {
  .container-anim {
    font-size: 18px; /* Even smaller font size for extra small screens */
  }
  .animation {
    height: 50px; /* Adjust height for extra small screens */
  }
}

@media (max-width: 340px) {
  .container-anim {
    font-size: 16px; /* Even smaller font size for extra small screens */
  }
  .animation {
    height: 50px; /* Adjust height for extra small screens */
  }
}